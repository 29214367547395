<template>

  <main id="content" role="main">

    <!-- Hero Section -->
    <div class="position-relative border-bottom">
      <div class="gradient-y-overlay-sm-gray-900 bg-img-hero" style="background-image:linear-gradient(to bottom, rgba(19, 24, 54, 0.30), rgba(19, 24, 54, 0.05)),url(./assets/img/cover-turtle.jpg);">
        <!-- Slide #1 -->
        <div class="container d-md-flex align-items-md-center vh-md-70 space-top-4 space-bottom-3 space-md-0">
          <div class="w-80 w-lg-50">
            <span class="d-block h3 text-white mb-2">
              Explore
            </span>
            <h1 class="text-white display-4 mb-0">
              The places you<br>can protect
            </h1>
            
          </div>
        </div>
        <!-- End Slide #1 -->
        <div class="postion-absolute bottom-0 right-0 left-0 text-center">
            <div class="container-fluid text-white py-5" style="opacity:0.3">
                <i class="fa fa-map-marker-alt"></i>
                <span> Roatán, Honduras - Photo by <a href="https://unsplash.com" target="_blank" style="color:inherit;text-decoration:underline">Erin Simmons</a></span>
            </div>
        </div>
      </div>
    </div>

    <!-- Features Section -->
    <div class="container space-2">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0 pt-8">
           <div id="mapid" class="min-h-450rem rounded-lg"></div>
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pb-lg-8">
            <!-- Title -->
            <div class="mb-5 mb-md-7">
              <h2 class="mb-1 text-primary" v-if="store.state.projects[featured] !== undefined">{{store.state.projects[featured].name}}</h2>
              <h3 class="mb-3" v-if="store.state.projects[featured] !== undefined">{{store.state.organizations[store.state.projects[featured].organization].name}}</h3>
              <p>Whether you're creating a subscription service, an on-demand marketplace, an e-commerce store, or a portfolio showcase, Front's unmatched functionality help you create the best possible product for your users.</p>
              <router-link class="btn btn-white" to="">Read more&nbsp;&nbsp;<i class="fa fa-external-link-alt" style="opacity:0.3"></i></router-link>
            </div>
            <!-- End Title -->

            <div class="row">
              <div class="col-6 mb-3 mb-md-5">
                <div class="pr-lg-4">
                  <span class="js-counter h2 text-primary">300</span>
                  <span class="h2 text-primary">+</span>
                  <p>Build a professional website with corporate and SaaS based components.</p>
                </div>
              </div>

              <div class="col-6 mb-3 mb-md-5">
                <div class="pr-lg-4">
                  <span class="js-counter h2 text-primary">70</span>
                  <span class="h2 text-primary">+</span>
                  <p>Take advantage of more than 70 pages designed with mobile-first in mind.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-lg-between">
        <img class="avatar-img rounded-lg" style="width: 110%; height:auto;" src="../../public/assets/img/reef.png" alt="Image Description">
      </div>
    </div>
    
  </main>

</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

const store = useStore()
const router = useRouter()
if (!store.state.userProfile.registrationCompleted){ router.push('/register') }

const featured = ref('')

onMounted(async () => {
  let mymap = L.map('mapid').setView([12.157139, -68.608917], 10);
  L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
    maxZoom: 18,
    id: 'mapbox/light-v9', //outdoors-v11
    tileSize: 512,
    zoomOffset: -1,
  }).addTo(mymap);
  await store.dispatch('fetchOrganizations')
  await store.dispatch('fetchProjects')
  
  for (const [i, p] of store.state.projects.entries()) {
    let popup = `<b>${p.name}</b><br>${store.state.organizations[p.organization].name}`
    let polygon = []
    for (const coordinate of p.location){
      polygon.push([coordinate._lat,coordinate._long])
    }
    let polyg = L.polygon(polygon).setStyle({color: '#48A684', fillColor:'#48A684'}).addTo(mymap).bindPopup(popup).on('click', function() {
        featured.value = i
      })
    if (p.featured===true){
      featured.value = i
      polyg.openPopup()
    }
  }
})

</script>

<style scoped>
#mapid { height: 300px; width: 500px }
</style>


